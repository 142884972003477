<template>
  <v-app>
    <v-app-bar app dark class="bg-black" style="height: 80px">
      <v-container>
        <img class="logo-custom" alt="Kawasaki" src="../assets/logo-white.png" />
        &nbsp; | &nbsp;
        <span class="primary--text title">Rewards Program</span>
      </v-container>
    </v-app-bar>
    <v-main class="login-bg">
      <v-container class="fill-height">
        <v-row justify="center">
          <v-col lg="6" md="8" sm="10" xs="12">
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer absolute>
      <Footer></Footer>
    </v-footer>
  </v-app>
</template>

<script>
import Footer from "@/components/Footer";
import { mapGetters } from "vuex";

export default {
  name: "LayoutPublicThin",
  components: {
    Footer
  },
  async mounted() {
    // clear existing session
    this.$auth.logout();

    this.loading = true;
    let programKey = process.env.VUE_APP_PROGRAM_KEY;
    await this.$api
      .get("/api/programs/byKey/" + programKey + "/public")
      .then(({ data }) => {
        let program = data;
        return Promise.all([
          this.$auth.storeSelectProgram(program),
          this.$auth.storeSelectClient(program.programGroup.client)
        ]);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  computed: {
    ...mapGetters(["selectedProgram"])
  }
};
</script>
